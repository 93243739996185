<template>
  <b-row>
    <b-col cols="6">
      <b-card title="Informasi">
        <validation-observer ref="simpleRules">
          <b-form>
            <form-v-select
              ref="branch_origin"
              v-model="branch_origin"
              label="Cabang Pengirim"
              rules="required"
              placeholder="Select Cabang Pengirim"
              @input="changeBranchOrigin"
            />
            <form-v-select
              ref="spb"
              v-model="spb"
              label="Nomor SPB"
              rules="required"
              placeholder="Select Nomor SPB"
              :item_text="'number'"
              @input="changeSPB"
            />
            <form-date
              v-model="request_date"
              rules="required"
              label="Tgl Permintaan"
              placeholder="Tgl Permintaan"
            />
            <form-date
              v-model="used_date"
              rules="required"
              label="Tgl Kebutuhan"
              placeholder="Tgl Kebutuhan"
            />
            <form-v-select
              ref="branch_destination"
              v-model="branch_destination"
              label="Cabang Tujuan"
              rules="required"
              placeholder="Select Cabang Tujuan"
              @input="changeBranchDestination"
            />
            <form-v-select
              ref="area"
              v-model="area"
              :selected="area"
              label="Area Layanan"
              placeholder="Select Area"
              @input="changeSelectArea"
            />
            <form-v-select
              ref="ulp"
              v-model="ulp"
              :selected="ulp"
              label="U L P"
              placeholder="Select ULP"
              @input="changeSelectUlp"
            />
            <form-v-select
              ref="project"
              v-model="project"
              :selected="project"
              label="Project"
              placeholder="Select Project"
            />
            <form-input v-model="note" label="Keterangan" placeholder="Keterangan" />
            <form-file v-model="file" label="File Berkas" />
            <hr />
            <b-form-group label-cols-md="4" label="">
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                type="button"
                @click.prevent="$router.go(-1)"
              >
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card title="Kendaraan">
        <form-v-select
          ref="vehicle"
          v-model="vehicle"
          label="Nopol MT"
          placeholder="Select Kendaraan"
          :item_text="'number_plate'"
          :options="optionVehicle"
        />
        <hr />
        <b-form-group label-cols-md="4" label="">
          <b-button
            style="float: right"
            size="sm"
            variant="success"
            type="button"
            @click="addDetail()"
          >
            <span>Tambah</span>
          </b-button>
        </b-form-group>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card title="Detail Kendaraan">
        <b-table striped responsive :items="details" :fields="table_details">
          <template #cell(_)="data">
            <span class="mr-1">
              <b-link @click="confirmDelete(data)">
                <b-badge variant="danger">
                  <feather-icon icon="TrashIcon" />
                </b-badge>
              </b-link>
            </span>
          </template>
          <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BTable,
  BLink,
  BBadge,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormFile from '@/views/base/form/FormFile.vue'
import FormDate from '@/views/base/form/FormDate.vue'
import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BForm,
    BCard,
    BTable,
    BLink,
    BBadge,
    BFormGroup,
    BFormSelect,
    BFormInput,
    baseSelect,
    FormInput,
    FormVSelect,
    BButton,
    BRow,
    BCol,
    FormFile,
    FormDate,
    BSpinner,
  },
  data() {
    return {
      dataurl: '/vehicle-mutation',
      baseroute: 'mutation',
      title: 'Mutasi',
      table_details: [
        { key: 'number', label: 'Nopol' },
        { key: 'variant.manufacture.name', label: 'Pabrikan' },
        { key: 'variant.model', label: 'Varian' },
        { key: 'initial_km', label: 'KM Tempuh' },
        { key: 'vendor.name', label: 'Kepemilikan' },
        '_',
      ],
      optionBranch: [],
      optionVehicle: [],
      optionSPB: [],
      details: [],
      vehicle_detail: [],
      spb:null,
      vehicle: null,
      branch_origin: null,
      branch_destination: null,
      request_date: moment().format('Y-MM-D'),
      used_date: moment().format('Y-MM-D'),
      note: '',
      file: null,
      path: null,
      loading: false,
      area: null,
      ulp: null,
      project: null,
      branch_id: null,
    }
  },
  mounted() {
    this.fetchDataRegion()
    if (this.$route.params.id) this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(this.dataurl + '/' + this.$route.params.id).then(res => {
        let data = res.data
        this.note = data.note
        this.used_date = data.used_date
        this.request_date = data.request_date
        this.path = data.file
        this.details = data.vehicles
        for (let i = 0; i < this.details.length; i++) {
          this.vehicle_detail.push(this.details[i]['id'])
        }
      })
    },
    fetchDataRegion() {
      this.$http.get('region').then(res => {
        this.optionBranch = res.data.data
        this.$refs.branch_origin.refreshData(this.optionBranch)
        this.$refs.branch_destination.refreshData(this.optionBranch)
      })
    },
    fetchDataSPB(branch_id) {
      this.$http.get('spb?filter[branch_id]='+branch_id+'&length=').then(res => {
        this.optionSPB = res.data.data
        this.$refs.spb.refreshData(this.optionSPB)
      })
    },
    fetchDataVahicle(spb_id) {
      this.$http.get('spb/'+spb_id+'/vehicle?length=').then(res => {
        this.optionVehicle = res.data.data.filter(vehicle => vehicle.vehicle_id)
        this.$refs.vehicle.refreshData(this.optionVehicle)
      })
    },
    fetchDataArea(parent_id) {
      var params = {}
      if (parent_id) {
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-area', { params: params }).then(res => {
        this.optionArea = res.data.data
        this.$refs.area.refreshData(this.optionArea)
      })
    },
    fetchDataUlp(parent_id) {
      var params = {}
      if (parent_id) {
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-center', { params: params }).then(res => {
        this.optionULP = res.data.data
        this.$refs.ulp.refreshData(this.optionULP)
      })
    },
    fetchDataProject(parent_id) {
      var params = {}
      if (parent_id) {
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-project', { params: params }).then(res => {
        this.optionProject = res.data.data
        this.$refs.project.refreshData(this.optionProject)
      })
    },
    changeBranchOrigin(data) {
      if (data == null) {
        this.spb = null
        this.$refs.spb.refreshData([])
      } else {
        this.fetchDataSPB(data.id)
      }
      this.resetDetail()
    },
    changeSPB(data) {
      if (data == null) {
        this.vehicle = null
        this.$refs.vehicle.refreshData([])
      } else {
        this.fetchDataVahicle(data.id)
      }
      this.resetDetail()
    },
    changeBranchDestination(data) {
      if (data != null) {
        this.fetchDataArea(data.id)
      } else {
        this.$refs.area.refreshData([])
        this.$refs.ulp.refreshData([])
        this.$refs.project.refreshData([])
      }
    },
    changeSelectArea(data) {
      if (data != null) {
        this.fetchDataUlp(data.id)
      } else {
        this.$refs.ulp.refreshData([])
        this.$refs.project.refreshData([])
      }
    },
    changeSelectUlp(data) {
      if (data != null) this.fetchDataProject(data.id)
      else this.$refs.project.refreshData([])
    },
    confirmDelete(data) {
      this.details.splice(data.index, 1)
      this.vehicle_detail.splice(data.index, 1)
    },
    resetDetail() {
      this.details = []
      this.vehicle_detail = []
    },
    addDetail() {
      if (this.vehicle == null)
        return this.$bvToast.toast('Please correct vehicle', {
          title: 'Error',
          solid: true,
          variant: 'danger',
        })

      if (!this.vehicle.vehicle_id)
        return this.$bvToast.toast('Please correct vehicle', {
          title: 'Error',
          solid: true,
          variant: 'danger',
        })

      this.details.push({
        id: this.vehicle.vehicle.id,
        number: this.vehicle.vehicle.number,
        variant: this.vehicle.variant,
        initial_km: this.vehicle.vehicle.initial_km,
        vendor: this.vehicle.vendor,
      })
      this.vehicle_detail.push(this.vehicle.vehicle.id)
      this.vehicle = null
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.details.length == 0)
            return this.$bvToast.toast('vehicle tidak boleh kosong', {
              title: 'Error',
              solid: true,
              variant: 'danger',
            })

          if (!this.file && !this.$route.params.id)
            return this.$bvToast.toast('file tidak boleh kosong', {
              title: 'Error',
              solid: true,
              variant: 'danger',
            })

          if (this.project) this.branch_id = this.project.id
          else if (this.ulp) this.branch_id = this.ulp.id
          else if (this.area) this.branch_id = this.area.id
          else this.branch_id = this.branch_destination.id

          let params = {
            branch_origin: this.branch_origin.id,
            branch_destination: this.branch_id,
            request_date: this.request_date,
            used_date: this.used_date,
            note: this.note,
            file: this.path,
            vehicles: this.vehicle_detail,
            spb_id: this.spb.id
          }

          this.loading = true

          if (this.$route.params.id && !this.file) {
            this.$http
              .put(this.dataurl + '/' + this.$route.params.id, params)
              .then(() => {
                this.successSubmit()
              })
              .catch(err => {
                this.errorSubmit(err)
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            let formData = new FormData()
            formData.append('file', this.file)
            this.$http
              .post('upload/file', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })
              .then(res => {
                if (res.data) {
                  params.file = res.data.path

                  let http = null
                  if (this.$route.params.id)
                    http = this.$http.put(
                      this.dataurl + '/' + this.$route.params.id,
                      params
                    )
                  else http = this.$http.post(this.dataurl, params)

                  http
                    .then(() => {
                      this.successSubmit()
                    })
                    .catch(err => {
                      this.errorSubmit(err)
                    })
                    .finally(() => {
                      this.loading = false
                    })
                } else {
                  return this.$bvToast.toast('Gagal Upload File', {
                    title: 'Error',
                    solid: true,
                    variant: 'danger',
                  })
                }
              })
              .catch(err => {
                this.errorSubmit(err)
              })
              .finally(() => {
                this.loading = false
              })
          }
        } else {
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant: 'danger',
          })
        }
      })
    },
    successSubmit() {
      this.$store.dispatch('sendMessage', '1')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      this.$router.go(-1)
    },
    errorSubmit(err) {
      const msg = err.response.data.message
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
  },
}
</script>
